<script setup>
import {defineProps} from "vue";
import CardComponent from "@/components/CardComponent.vue";

const props = defineProps({
  title : {
    type: String,
    required: true,
  },
  text : {
    type: String,
    required: true,
  },
  cards : {
    type: JSON,
    required: true,
  }
})
</script>

<template>
<div class="main-container">
  <div class="container">
    <div class="top-banner">
      <h1 class="title">{{ props.title }}</h1>
      <p class="text">{{ props.text }}</p>
    </div>
    <div class="cards">
      <CardComponent v-for="card in props.cards" v-bind:key="card.id" :title="card.title" :imgSrc="card.imgSrc" :text="card.text"/>
    </div>
  </div>
</div>
</template>

<style scoped>
.main-container {
  background: linear-gradient(to top right, #260d3f, #000000);
  padding: 3rem 0;
}

.container {
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
  gap: 1rem;
  color: #dfdfdf;
}

.title {
  text-align: center;
}

.text {
  text-align: justify;
  -moz-text-align-last: center;
  text-align-last: center;
}

.cards {
  display: flex;
  flex-direction: row;
  margin: auto;
  gap: 2rem;
}

@media (max-width: 768px) {
  .cards {
    flex-direction: column;
  }
}
</style>