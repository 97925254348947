<script setup>
import {defineProps} from 'vue';

const props = defineProps({
  title : {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
  imgSrc: {
    type: String,
    required: true,
  }
})
</script>

<template>
  <div class="custom-card">
    <div class="custom-card-content">
      <img :src="props.imgSrc" alt="" class="custom-card-img"/>
      <h3 class="custom-card-title">{{ props.title }}</h3>
      <p class="custom-card-text">{{ props.text }}</p>
    </div>
  </div>
</template>

<style scoped>
.custom-card-img{
  height: 150px;
  width: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.custom-card {
  border: 1px solid #dfdfdf;
  width: 300px;
  border-radius: 4px;
}

.custom-card-content {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 2rem;
}

.custom-card-text {
  text-align: justify;
  -moz-text-align-last: center;
  text-align-last: center;
}
</style>