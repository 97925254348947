<template>
  <nav :class="['navbar', 'navbar-dark', 'navbar-expand-lg', 'fixed-top', {'navbar-scrolled' : isScrolled, 'navbar-top' : !isScrolled}]">
    <div class="container">
      <a class="navbar-brand text-light" href="#">{{name}}</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="ms-auto navbar-nav mb-2 mb-lg-0">
          <li v-for="section in props.sections" v-bind:key="section.id" class="nav-item">
            <a :href="`#${section.id}`" :class="['nav-link', {active: props.currentSection === section.id}]">{{ section.name }}</a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">
              <i class="bi bi-instagram"></i>
            </a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">
              <i class="bi bi-tiktok"></i>
            </a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">
              <i class="bi bi-youtube"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>

  </nav>
</template>

<script setup>
  import { defineProps, ref, onMounted, onBeforeUnmount } from "vue";

  const props = defineProps({
    name: { type: String, required: true },
    sections :{
      type: Array,
      required: true
    },
    currentSection: {
      type: String,
      required: false
    }
  })

  const isScrolled = ref(false);

  const handleScroll = () => {
    isScrolled.value = window.scrollY > 0;
  }

  onMounted(() => {
    window.addEventListener('scroll', handleScroll);
  })

  onBeforeUnmount(() => {
    window.removeEventListener('scroll', handleScroll);
  })
</script>

<style scoped>
.navbar {
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.navbar-top{
  z-index: 1030;
  background-color: transparent;
}

.navbar-scrolled{
  background-color: rgba(0, 0, 0, 0.75);
}
</style>