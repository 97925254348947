<template>
  <div class="image-container" :id="config.id">
    <img :src="props.src" alt="" class="image"/>
    <div class="text-overlay">
      <h1>{{ props.textOverlay }}</h1>
      <p>{{ props.subtextOverlay }}</p>
    </div>
    <div v-if="props.nextSection" class="arrow" @click="goToNextSection">
      ⬇️
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  config: {
    type: JSON,
    required: true
  },
  src: {
    type : String,
    required: true
  },
  textOverlay: {
    type : String,
    required: false
  },
  subtextOverlay: {
    type : String,
    required: false
  },
  nextSection: {
    type: String,
    required: false
  }
}
)

function goToNextSection(){
  if (props.nextSection){
    const section = document.querySelector(props.nextSection);
    if (section){
      section.scrollIntoView({behavior: "smooth"});
    }
  }
}

</script>

<style scoped>
.image-container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.image-container .image{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent noir pour améliorer la lisibilité du texte */
}

</style>
