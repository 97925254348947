<script setup>
import { defineProps} from 'vue';

const props = defineProps({
  year : {
    type: Number,
    required: true,
  },
  company : {
    type: String,
    required: true,
  }
})
</script>

<template>
<footer class="footer">
    <span class="copyright-text">© {{props.year}} {{props.company}}. Tous droits réservés.</span>
</footer>
</template>

<style scoped>

.footer{
  background-color: #222222;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem;
}

.copyright-text{
  color: #dfdfdf;
}

</style>