<script setup>
import {defineProps} from 'vue';
const props = defineProps({
  title : {
    type: String,
    required: true
  },
  videos : {
    type: Array,
    required: true,
  }
})
</script>

<template>
  <div class="carousel-container">
    <h1 class="title">{{props.title}}</h1>
    <div id="carousel-video" class="carousel slide carousel-fade">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#carousel-video" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carousel-video" data-bs-slide-to="1" aria-label="Slide 2"></button>
      </div>
      <div class="carousel-inner">
        <div v-for="video in videos"  v-bind:key="video.id" :class="['carousel-item', (video.id === 0 ? 'active' : '')]">
          <iframe
              width="1280"
              height="720"
              :src="video.url"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
          ></iframe>
          <div class="carousel-caption d-none d-md-block">
            <h3>{{video.title}}</h3>
            <p>{{video.desc}}</p>
          </div>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carousel-video" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carousel-video" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</template>


<style scoped>
li {
  list-style-type: none;
}

.carousel-container{
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 3rem 0;
  gap: 2rem;
}


</style>