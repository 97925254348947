<script setup>
import {defineProps} from "vue";

const props = defineProps({
  title : {
    type: String,
    required: true,
  },
  text : {
    type: String,
    required: true,
  },
  src : {
    type: String,
    required: true,
  }
})
</script>

<template>
  <div class="container">
    <img :src="props.src" alt="" class="image"/>
    <div class="text-container">
      <h1 class="title">{{ props.title }}</h1>
      <p class="text">{{ props.text }}</p>
    </div>

  </div>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: row;
  align-content: center;
  padding: 3rem;
  column-gap: 2rem;
}

.image {
  max-width: 300px;
}

.title {
  text-align: center;
}

.text{
  text-align: justify;
  -moz-text-align-last: center;
  text-align-last: center;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: justify;
  padding-left: 3rem;
  overflow: hidden;
}

@media (max-width: 768px) {
  .text-container {
    padding: 0;
  }

  .image {
    margin: 0 auto;
  }

  .container {
    display: flex;
    flex-direction: column-reverse;
    column-gap: 2rem;
    align-content: center;
    justify-content: center;
  }
}
</style>