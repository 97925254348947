<script setup>
import {defineProps} from "vue";

const props = defineProps({
  title : {
    type: String,
    required: true,
  },
  email : {
    type: String,
    required: true,
  }
})
</script>

<template>
  <div class="container-custom">
    <p class="title">{{ props.title }}</p>
    <a :href="'mailto:'+props.email" class="email">{{ props.email }}</a>
  </div>
</template>

<style scoped>
.container-custom {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  text-align: center;
  padding: 3rem 0;
  gap: 5px;
  background-color: #260d3f;
}

.title, .email{
  font-size: 1.5rem;
  text-decoration: none;
  color: var(--bs-light);
  margin: 0;
}

.email:after{
  content: "";

}

.email::after {
  content: '→';
  position: absolute;
  transform: translate(-5px);
  opacity: 0;
  color: var(--bs-light);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.email:hover::after {
  content: '→';
  opacity: 1;
  transform: translate(5px);
  margin-left: 2px;
}

@media only screen and (max-width: 768px) {
  .container {
    flex-direction: column;
  }
}

</style>