<template>
  <HeaderComponent :name='name' :sections='sections' :currentSection='currentSection'/>
  <ImageComponent :config='sections.at(0)' :src="'https://picsum.photos/1920/1080'" :text-overlay="name" :subtext-overlay="'Créateur de contenu'"/>
<!--  <VideoComponent :id='sections.at(0).id' :src="HomeBackgroundVideo" :text-overlay="name" :subtext-overlay="'Créateur de contenu'"/>-->
  <TextWithImageComponent :id='sections.at(1).id' :title="'A propos de moi'" :src="'https://picsum.photos/1080/1920'" :text="'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'"/>
  <CardContainterComponent :id="sections.at(2).id" :title="'Prestations'" :cards="cardsContent" :text="'Montage vidéo global : création d\'une vidéo du tournage à la post-production. Mettre en avant la prise de besoin du client et l’objectif visé avec la production.'"/>
  <CarouselComponent :id="sections.at(3).id" :title="'Mes créations'" :videos="carouselVideos"/>
  <ContactComponent :id="sections.at(4).id" :title="'Un projet ? Rencontrons nous !'" :email="'placeholder@gg.net'"/>
  <FooterComponent :year="2024" :company="'MaqsProduction'"/>
</template>

<script setup>
import HeaderComponent from "@/components/HeaderComponent.vue";
import ImageComponent from "@/components/ImageComponent.vue";
import TextWithImageComponent from "@/components/TextWithImageComponent.vue";
import CardContainterComponent from "@/components/CardContainterComponent.vue";
import { ref, onMounted, onBeforeUnmount } from "vue";
import FooterComponent from "@/components/FooterComponent.vue";
import CarouselComponent from "@/components/CarouselComponent.vue";
import ContactComponent from "@/components/ContactComponent.vue";
// import VideoComponent from "@/components/VideoComponent.vue";

// Assets import
// const HomeBackgroundVideo = require('@/assets/home.mp4');

const sections = [
  {id: 'home', name: 'Accueil'},
  {id: 'about', name: 'A propos'},
  {id: 'services', name: 'Prestations'},
  {id: 'creations', name: 'Mes créations'},
  {id: 'contact', name: 'Nous contacter'}
]

const cardsContent = [
  {id : 'exchangeCard' ,title: 'Echange', text: 'Définir vos besoins, vous écouter et vous accompagner dans la réalisation et la diffusion de votre projet.', imgSrc: 'https://picsum.photos/150/150'},
  {id : 'filmCard' ,title: 'Tournage', text: 'Nous disposons d’un matériel professionnel haut de gamme, adapté à tous types de tournage pour vous offrir une qualité d’image optimale', imgSrc: 'https://picsum.photos/150/150'},
  {id : 'postProdCard' ,title: 'Post-Production', text: 'Maqs Production mettra toute sa créativité au service de votre projet. Une production originale à votre image.', imgSrc: 'https://picsum.photos/150/150'},
]


const carouselVideos = [
  {id: 0, url: 'https://www.youtube.com/embed/eTafIfmvd0g?si=MQb9N5ik4tvfhiPO', title :'Detailling Mercedes w201', desc : 'Lorem ipsum'},
  {id: 1, url: 'https://www.youtube.com/embed/QCmeGEC8K7E?si=ZAvnUVcnuOjJazrW', title :'Bordeaux Edit', desc: 'Lorem ipsum'},
];
const currentSection = ref('home')
const name = ref('Maqs Production')

let observer;

const updateSection = (entries) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      currentSection.value = entry.target.id;
    }
  })
}

onMounted(() => {
  observer = new IntersectionObserver(updateSection, {threshold: 0.9});
  sections.forEach(section => {
    observer.observe(document.querySelector('#'+section.id));
  });
})

onBeforeUnmount(() => {
  if (observer){
    observer.disconnect();
  }
})

</script>

<style>
</style>
